<template>
    <div>
        <div>
            <van-cell-group title="添加荣誉">
                <van-field v-model="model.NAME" type="text" label="荣誉名称" placeholder="请输入荣誉名称" />
                <van-field label="荣获日期" :value="model.OBTAIN_DT | datetime('YYYY-MM-DD')" @click="time.show = true"
                    placeholder="请选择荣获日期" is-link />
                <van-popup v-model="time.show" round position="bottom">
                    <van-datetime-picker type="date" :min-date="time.minDate" v-model="time.curDate"
                        @cancel="time.show = false" @confirm="timeConfirm" />
                </van-popup>
            </van-cell-group>
            <van-cell-group title="上传图片">
                <!-- <van-uploader :after-read="afterRead" v-model="model.IMAGES" @delete="model.IMAGES = ''" max-count="9"
                    style="margin: 10px 0px 0px 10px" /> -->
                <van-uploader :after-read="afterRead" multiple v-model="picList" @delete="deleteImg" max-count="9"
                    style="margin: 10px 0px 0px 10px" />
            </van-cell-group>
            <div style="margin: 15px">
                <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
                    loading-text="正在提交。。。">确认创建</van-button>
                <br />
                <van-button plain type="default" size="large" icon="cross" block to="/org/teahonor">取消返回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { Toast } from "vant";
export default {
    data() {
        return {
            model: {},
            images: [],
            picList: [],
            ret: {
                loading: false
            },
            time: {
                show: false,
                minDate: moment().add(-1, 'y').toDate(),
                curDate: moment().toDate(),
            },
        }
    },
    created() {

    },
    methods: {
        timeConfirm(val) {
            this.time.show = false;
            this.model.OBTAIN_DT = val;
        },

        afterRead(files) {
            let self = this
            if (!Array.isArray(files)) {
                files = [files]
            }
            files.forEach(item => {
                let b64Arr = item.content.split(',')
                this.whale.remote.getResult({
                    url: "/api/Mobile/ORG/TeaHonorApi/UploadHonorImage",
                    data: { base64: b64Arr[1] },
                    completed(its) {
                        self.images.push(its.DATA)
                    },
                })
            })
            setTimeout({}, 100)
        },
        deleteImg(files, { index }) {
            this.images.splice(index, 1)
        },
        submit() {
            let self = this;
            self.model.IMAGES = self.images.join(',')

            if (!self.model.NAME) {
                Toast.fail("请输入荣誉名称")
                return;
            }
            if (!self.model.OBTAIN_DT) {
                Toast.fail("请选择获取日期")
                return;
            }
            self.ret.loading = true;
            this.whale.remote.getResult({
                url: "/api/Mobile/ORG/TeaHonorApi/CreateNew",
                data: this.model,
                finally() {
                    self.ret.loading = false;
                },
                completed() {
                    self.$dialog.confirm({
                        title: "完成",
                        message: "教师荣誉添加成功",
                        theme: "round",
                        confirmButtonText: "继续添加",
                        cancelButtonText: "返回"
                    }).then(() => {
                        self.$router.go(0);
                    }).catch(() => {
                        self.$router.push("/org/teahonor")
                    })
                }
            })
        }
    }
}
</script>
<style scoped></style>